<template>
	<div class="partBox">
		<p class="title" v-show="type != 3">{{title}}</p>	
		<el-row class="item">
			<el-col :xs="12" :sm="6">
				
				<el-select v-model="params.areaid" :placeholder="$t('system.choice')" @change="changeArea" v-show="type != 3" class="selectBox" >
					<el-option v-if="type == 1" :key="0" :label="$t('system.total')" :value="0"></el-option>
					<el-option v-for="item in areaList" :key="item.ID" :label="item.area_name" :value="item.ID"></el-option>
				</el-select>
				<span style="line-height: 32px;" v-show="type == 3">{{title}}</span>	
			</el-col>
			<el-col :xs="12" :sm="18" style="text-align: right;">
				<span 
					v-for="item in timeList" 
					:key="item.key"
					:class="{'active': interval == item.key}"
					class="timeText"
					@click="changeInterval(item.key)"
				>{{$t(item.value)}}</span>
				<el-date-picker
					v-model="time"
					type="daterange"
					:format="momentStamp('date')"
					:start-placeholder="$t('system.startTime')"
					:end-placeholder="$t('system.endTime')"
					:disabled-date="disabledDate"
					@change="changeTime"
					class="dateBox"
					:default-time="defaultTime"
				>
				</el-date-picker>	
				<!-- <img :src="totalImg.dateImg" width="16" class="dateImg"/> -->
				<i class="el-icon-date dateImg"></i>
				<i class="el-icon-sort-up sortImg"></i>
			</el-col>
		</el-row>
	</div>	
</template>

<script>
import {
  defineComponent,
	onMounted,
	onBeforeUpdate,
  reactive,
  watch,
  ref,
  nextTick,
  PropType,
  toRefs,
} from "vue";
import { regionList } from '@/apis/region'
import { areaTaskDisinfect, roomTaskDisinfect, robotDisinfect } from '@/apis/robot'
import { useRouter, useRoute } from "vue-router";
import { ElMessage } from 'element-plus';
import moment from "moment";
import { totalImg, momentStamp } from "@/utils/robot";
import { useI18n } from 'vue-i18n'
import i18n from '@/locales/index'
let _t = i18n.global.t

export default {
	props: ['title', 'type'],
  setup(props, context) {
		const router = useRouter()
		const route = useRoute()
		const { t } = useI18n()
		const defaultTime = [moment().subtract(1, 'months'), moment()]
    const state = reactive({
			areaList: [],
			momentStamp: momentStamp,
			params: {
				areaid: 0,
				begin: -1,
				end: -1,
				interval: 'day',
				location: Intl.DateTimeFormat().resolvedOptions().timeZone
			},
			defaultTime: [
        new Date(2000, 1, 1, 0, 0, 0),
        new Date(2000, 2, 1, 23, 59, 59),
      ],
			interval: '',
			totalImg: totalImg(),
			title: props.title,
			type: props.type,
			isShow: true,
			time: [],
			timeList: [{
				key: 'day',
				value: 'dataReport.today'
			}, {
				key: 'week',
				value: 'dataReport.lastWeek'
			}, {
				key: 'month',
				value: 'dataReport.lastMonth'
			}, {
				key: 'year',
				value: 'dataReport.lastYear'
			}],
			// pickerOptions: {
			// 	onPick: ({ maxDate, minDate }) => {
			// 		state.time = minDate.getTime()
			// 		if (maxDate) {
			// 			state.time = ''
			// 		}
			// 	},
			// 	disabledDate: (time) => {
			// 		if (!isNull(state.time)) {
			// 			const one = 30 * 24 * 3600 * 1000
			// 			const minTime = state.time - one
			// 			const maxTime = state.time + one
			// 			return time.getTime() < minTime || time.getTime() > maxTime
			// 		}
			// 	}
			// },
			disabledDate(time) {
        return time.getTime() > moment().subtract(1, 'days')
			},
			getIntervalTime(val) {
				let begin, end, time, params
				switch(val) {
					case 'day':
						begin = moment().startOf('day')/1000
						end = moment().endOf('day')
						time = [begin, end]
						break
					case 'week':
						begin = moment().subtract(1, 'weeks').startOf('day').unix()
						end = moment()
						time = [begin, end]
						break
					case 'month':
						begin = moment().subtract(1, 'months').startOf('day').unix()
						end = moment()
						time = [begin, end]
						break
					case 'year':
						begin = moment().subtract(1, 'years').startOf('month').unix()
						end = moment()
						time = [begin, end]
						break
				}
				params = { 
					begin: parseInt(begin.valueOf()), 
					end: parseInt(end.valueOf()/1000),  
					// time 
				}
				return params
			},
			changeArea(val) {
				state.params.areaid = val
				state.getAreaTaskDisinfect()
				// if(props.type == 2) {
				// 	localStorage.areaid_b = val
				// }
			},
			changeInterval(val) {
				state.interval = val
				if (val == 'year') {
					state.params.interval = 'month'
				} else {
					state.params.interval = 'day'
				}
				const { begin, end, time} = state.getIntervalTime(val)
				state.params.begin = begin
				state.params.end = end
				state.time = time
				state.getAreaTaskDisinfect()
			},
			changeTime(val) {
				if (val == null) {
					state.params.begin = -1
					state.params.end = -1
					state.interval = 'week'
				} else {
					let a = moment(val[0]).valueOf(),
							b = moment(val[1]).valueOf()
					let interval = moment.duration(moment(b).diff(moment(a), "day"))
					if (interval > 30) {
						ElMessage.closeAll()
						ElMessage({
							message: _t('dataReport.intervalTip')
						})
						state.time = []
						return
					} else {
						state.params.begin = parseInt(moment(val[0]).valueOf()/1000)
						state.params.end = parseInt(moment(val[1]).valueOf()/1000)
					}
					state.interval = ''
				}
				state.params.interval = 'day'
				state.getAreaTaskDisinfect()
			},
			getAreaList() {
				let  params = {
					page: 1,
					pageSize: 10000,
					limit: false,
				}
				regionList(params).then((res => {
					if (res && res.code == 200) {
						state.areaList = res.data.lists
						let arr = []
						arr = res.data.lists
						if(props.type == 1) {
							state.areaList = arr
							let list = res.data.lists, ID = null
							if(list && list.length > 0) {
								state.params.areaid = 0
							}
						} 
						if(props.type == 2) {
							let list = res.data.lists, ID = null
							if(list && list.length > 0) {
								let _areaid = list[0].ID
								// if (localStorage.areaid_b) {
								// 	list.map(item => {
								// 		if (item.ID == localStorage.areaid_b/1) {
								// 			ID = item.ID
								// 		}
								// 	})
								// 	if (ID) {
								// 		state.params.areaid = ID
								// 	} else {
								// 		state.params.areaid = _areaid
								// 		// localStorage.areaid_b = _areaid
								// 	}
								// } else {
									state.params.areaid = _areaid
									// localStorage.areaid_b = _areaid
								// }
								state.getAreaTaskDisinfect()
							}
						}
						state.areaList = arr
					}
				}))
			},
			getAreaTaskDisinfect() {
				if (props.type == 1) {
					console.log(state.params,'转化时间',moment(state.params.end*1000).format(),new Date())
					areaTaskDisinfect(state.params).then((res => {
						if (res.code == 200) {
							let val = JSON.parse(res.data), params, isShow,
							data, 
							timeList = [], 
							countList = [], 
							durationList = []
							data = val.Data
							if(data && data.length > 0) {
								isShow = true
								data.map(item => {
									timeList.push(item.DateTime)
									countList.push(item.Count)
									// let _value = (item.Duration/3600).toFixed(5)
									durationList.push(String(item.Duration))
								})
								params = { timeList, countList, durationList }
								// state.lineChartData = ref(params)
							} else {
								params = {}
								isShow = false
							}
							let value = {
								params,
								isShow
							}
							context.emit("getChartData_l", value);
						}
					}))
				} else if (props.type == 2) {
					if(state.params.areaid == 0 || state.params.areaid == '') {
							let value = {
								params: {},
								isShow: false
							}
							context.emit("getChartData_p", value);
						return 
					}
					roomTaskDisinfect(state.params).then((res => {
						if (res.code == 200) {
							let val = JSON.parse(res.data), params, isShow,
							data, height,
							roomList = [], 
							countList = [], 
							durationList = []
							data = val.Data
							const	sortId = (a,b) =>{  
								return b.Duration - a.Duration  
							}
							const	sortId_c = (a,b) =>{  
								return b.Count - a.Count  
							}
							let data_c = data.sort(sortId_c)
							let _data = data.sort(sortId)
							if(_data && _data.length > 0) {
								isShow = true
								_data.map(item => {
									roomList.push(item.RoomName)
									countList.push(item.Count)
									durationList.push(item.Duration)
								})
								params = { roomList, countList, durationList, max_d:Math.max(...durationList), max_c: Math.max(...countList) }
								// state.lineChartData = ref(params)
							} else {
								params = {}
								isShow = false
							}
							let value = { params, isShow, height }
							context.emit("getChartData_b", value);
						}
					}))
				} else if (props.type == 3) {
					robotDisinfect(state.params).then((res => {
						if (res.code == 200) {
							let val = JSON.parse(res.data), params, isShow,
							data, 
							pieList = [], totalCount, 
							robotNameList = []
							const	sortId = (a,b) =>{  
								return b.value - a.value  
							}
							data = val.Data
							totalCount = val.TotalCount
							let sum = 0
							if(data && data.length > 0) {
								isShow = true
								data.map(item => {
									sum += item.Count
								})
								data.map(item => {
									let obj = {
										name: item.SN,
										robotName: item.robot_nickname??item.Name,
										value: item.Count,
										status: item.Status,
										percent: item.Count == 0 ? 0 : (((item.Count/sum)*100).toFixed(2))
									}
									pieList.push(obj)
									robotNameList.push(item.SN)
								})
								let _pieList = pieList.sort(sortId)
								params = { sum, pieList: _pieList, robotNameList, totalCount }
							} else {
								params = {}
								isShow = false
							}
							let value = {
								params,
								isShow
							}
							context.emit("getChartData_p", value);
						}
					}))
				}
			},
		});
    onMounted(async () => {
			await state.changeInterval('week');
			state.getAreaList()
			state.getAreaTaskDisinfect()
			nextTick(() => {
				
				if(props.type == 1) {
					state.params.areaid = 0
				}
				state.changeInterval('week');
			});
			
		});
    return {
      ...toRefs(state)
    };
  }
};
</script>
<style lang="scss">
.partBox {
	.el-icon-arrow-up:before {
		color: #000
	}
	.selectBox {
		width: 100%;
		height: 36px!important;
	}
	.select-trigger {
		height: 36px;
		.el-input__inner {
			// border-color: rgba(0, 0, 0, 0.45);
			border-radius: 2px;
		}
	}
	.dateBox {
		// width: 256px;
		height: 36px;
		border-radius: 2px;
		border-color: #ddd;
		.el-input__icon {
			display: none;
		}
		.el-range-separator {
			opacity: 0;
		}
	}
	.el-date-editor .el-range-input{
		width:41%;
	}
}
</style>
<style lang="scss" scoped>
.partBox {
	margin-top: 30px;
	// min-height: 520px;
	background: #fff;
	.title {
		padding: 20px;
		margin: 0;
	}
	.item {
		padding: 20px;
		border-top: 1px solid #DCDFE6;
		border-bottom: 1px solid #DCDFE6;
	}
	.timeText {
		display: inline-block;
		vertical-align: middle;
		padding-right: 24px;
		cursor: pointer;
	}
	.active {
		color: #1890FF;
	}
	.noChart {
		margin-top: 130px;
		color: #999;
		text-align: center;
	}
	.chartBox {
		padding: 30px 20px 0;
	}
	.dateImg {
		position: absolute;
    right: 28px;
		bottom: 30px;
		color: #00000040;
	}
	.sortImg {
		position: absolute;
    right: 210px;
		bottom: 30px;
		color: #00000040;
    transform: rotateX(180deg) rotate(90deg);
	}
}
</style>
